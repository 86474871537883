import React, { useState } from 'react';
import '../App.css';

const renderLink = (url: string, name: string, inNewWindow = true) =>
 <li>
    <a className="glowup" rel="noopener noreferrer" href={url} target={`${inNewWindow ? "_blank" : "_self"}`}>{name}</a>
  </li>

const Links = () => {
	const [ showing, setShowing ] = useState(false);
  const toggle = () => setShowing(!showing);
    return (
      <div className="Links">
				<button className={`glowup ${showing ? "showing" : "normal"}`} onClick={toggle}>LINKS</button>
        {
				showing && <ul>
          {renderLink("https://blog.themillhousegroup.com","Blog")}
          {renderLink("https://www.github.com/themillhousegroup", "Github")}
          {renderLink("https://stackoverflow.com/users/649048/millhouse", "Stack Overflow")}
          {/* {renderLink("https://www.twitter.com/millhousetweets", "Twitter")} */}
        </ul>
				}
        { showing && <hr /> }
        {
        showing && <ul> {renderLink("/login","Login", false)} </ul>
        }
      </div>
    );
}

export default Links;
