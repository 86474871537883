import styled from '@emotion/styled';
import { Content, H1, H4, Headline, ItemBox, ItemBoxFooter } from '../components/ItemBox';
import { FormEvent, useState } from 'react';
import { LoginRequestJson } from '../../netlify/edge-functions/types/network.types';

const FieldHolder= styled('div')`
	display: flex;
  flex-direction: column;
	margin: 0 auto;

  input, label {
    display: block;
    margin: 0.5em 5em 0 5em;
  }

  input {
    margin-left: 6em;
    margin-right: 6em;
  }
`

const FunkyButton = styled('button')`
	font-size: 16px;
	font-family: 'Magra', sans-serif;
	letter-spacing: 2px;
	outline: none;
  cursor: pointer;
  display: inline;
  padding: 4px;
  background: #440088;
	border: 2px solid #509; 
  border-radius: 8px;
`

type LoginForm = {
  username: HTMLInputElement;
  password: HTMLInputElement;
}
export const Login = () => {
  const [message, setMessage] = useState("");
  const [working, setWorking] = useState(false);

  const submitIt = async (formElementEvent: FormEvent<HTMLFormElement>) => {
    setWorking(true);
    formElementEvent.preventDefault();
    const { username, password } = formElementEvent.target as unknown as LoginForm;

    const bodyJson: LoginRequestJson = {
      u: username.value,
      p: password.value,
      t: new Date().getMilliseconds(),
    };

    const body = JSON.stringify(bodyJson);

    const headers = {
      "content-type": "application/json"
    };
    const response = await fetch("/login", {
      method: 'POST',
      body,
      headers
    });

    setWorking(false);
    setMessage(await response.text());
  }

  return (
    <ItemBox>
      <form onSubmit={submitIt}>
        <Headline><H1>Login</H1></Headline>
        <Content>
            <FieldHolder>
              <label htmlFor="username">Username</label>
              <input type='text' name='username'></input>
              <label htmlFor="password">Password</label>
              <input type='password' name='password'></input>
              <H4>{message}</H4>
            </FieldHolder>
        </Content>
        <ItemBoxFooter>
          <FunkyButton className='glowup' type='submit'>{working ? 'LOADING':  'SUBMIT'}</FunkyButton>
        </ItemBoxFooter>
      </form>
    </ItemBox>
  )
}