export const Sandbox = () => {
  return (
    <>
      <h1>H1</h1>
      <h2>H2</h2>
      <h3>H3</h3>
      <h4>H4</h4>
      <h5>H5</h5>
      <h6>H6</h6>
      <p>This is p text</p>
      <h4>Flip Start</h4>
        
      <div className="flip-container">
        <div className="flipper">
          <div className="front">
            <img src="https://picsum.photos/id/411/300/200" alt="Front Image" />
          </div>
          <div className="back">
            <img src="https://picsum.photos/id/249/300/200" alt="Back Image" />
          </div>
        </div>
      </div>

      <h4>Flip End</h4>
  </>
  )
}