import styled from "@emotion/styled";

export const H1 = styled('h1')`
  margin: 0;
  padding: 0;
`;
export const H4 = styled('h4')`
  text-align: center;
  margin: 0.5em auto;
  min-height: 1em;
`;


export const ItemBox = styled('div')`
  background-color: #111;
  box-shadow: 0 10px 10px -4px var(--shadow);
  color: #eee;
  width: 75vw;
  max-width: 600px;
  margin: 3em auto;
  opacity: .9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;


export const Headline = styled('div')`
  position: relative;
  top: -0.5em;
  left: -0.5em;
  background-color: var(--main-purple);
  border-bottom: 2px solid var(--silver-rail);
  box-shadow: 0 10px 10px -4px var(--shadow);

  color: #eee;
  width: 8em;
  height: 1.1em;
  padding: 0.5em;
`;

export const Content = styled('div')`
  color: #eee;
  padding: 0.5em;
`

export const ItemBoxFooter = styled('div')`
  background-color: var(--main-purple);
  border-top: 2px solid var(--silver-rail);

  text-align: center;
  width: 100%;
  height: 3em;
  line-height: 3em;
`;


