import { format, parse } from "date-fns";

export type CareerJob = {
  companyName: string;
  location: string;
  startDate: Date;
  endDate?: Date;
  techologies: Technology[];
};

export type Technology = {
  name: string;
  techType: TechType;
}

export type TechType = "Language" | "Database" | "Platform"

const DT_FORMAT = "MMM yyyy"

export const parseDt = (s: string):Date => {
  return parse(s, DT_FORMAT, new Date())
}
export const renderDt = (d: Date):string => {
  return format(d, DT_FORMAT)
}

const C: Technology = { name: "C", techType: "Language"}
const CPLUSPLUS: Technology = { name: "C++", techType: "Language"}
const CSHARP: Technology = { name: "C#", techType: "Language"}
const JAVA: Technology = { name: "Java", techType: "Language"}
const SCALA: Technology = { name: "Scala", techType: "Language"}
const CLOJURE: Technology = { name: "Clojure", techType: "Language"}
const JAVASCRIPT: Technology = { name: "JavaScript", techType: "Language"}
const TYPESCRIPT: Technology = { name: "TypeScript", techType: "Language"}
const TERRAFORM: Technology = { name: "Terraform", techType: "Language"}
const GRAPHQL: Technology = { name: "GraphQL", techType: "Language"}
const PLSQL: Technology = { name: "PL/SQL", techType: "Language"}
const SQL: Technology = { name: "SQL", techType: "Language"}
const ORACLE: Technology = { name: "Oracle", techType: "Database"}
const MONGODB: Technology = { name: "MongoDB", techType: "Database"}
const DYNAMODB: Technology = { name: "DynamoDB", techType: "Database"}
const AWS: Technology = { name: "AWS", techType: "Platform"}

export const JOHN_CAREER: CareerJob[] = [
{
  companyName: "Agilent Technologies",
  location: "Melbourne, AU",
  startDate: parseDt("Jan 2000"),
  endDate: parseDt("Mar 2003"),
  techologies: [C, CPLUSPLUS]
},
{
  companyName: "MTData",
  location: "Melbourne, AU",
  startDate: parseDt("Apr 2003"),
  endDate: parseDt("Jan 2005"),
  techologies: [C, CSHARP]
},
{
  companyName: "DWS (Origin Energy)",
  location: "Melbourne, AU",
  startDate: parseDt("Feb 2005"),
  endDate: parseDt("Feb 2007"),
  techologies: [JAVA, PLSQL, SQL, ORACLE]
},
{
  companyName: "Volutio",
  location: "Chippenham, UK",
  startDate: parseDt("Feb 2007"),
  endDate: parseDt("Feb 2008"),
  techologies: [JAVA, JAVASCRIPT]
},
{
  companyName: "Staellium",
  location: "London, UK",
  startDate: parseDt("Feb 2008"),
  endDate: parseDt("Jun 2008"),
  techologies: [JAVA, JAVASCRIPT]
},
{
  companyName: "Icom",
  location: "London, UK",
  startDate: parseDt("Jul 2008"),
  endDate: parseDt("Dec 2009"),
  techologies: [JAVA, JAVASCRIPT, SQL]
},
{
  companyName: "Reece",
  location: "Melbourne, AU",
  startDate: parseDt("Dec 2009"),
  endDate: parseDt("Dec 2010"),
  techologies: [JAVA, SQL]
},
{
  companyName: "Sensis",
  location: "Melbourne, AU",
  startDate: parseDt("Jan 2011"),
  endDate: parseDt("Aug 2011"),
  techologies: [JAVA, JAVASCRIPT]
},
{
  companyName: "Open Universities Australia",
  location: "Melbourne, AU",
  startDate: parseDt("Sep 2011"),
  endDate: parseDt("Feb 2013"),
  techologies: [JAVA, JAVASCRIPT]
},
{
  companyName: "Australia Post Digital Mailbox",
  location: "Melbourne, AU",
  startDate: parseDt("Mar 2013"),
  endDate: parseDt("Mar 2015"),
  techologies: [JAVA, JAVASCRIPT, SCALA, CLOJURE]
},
{
  companyName: "Sensis",
  location: "Melbourne, AU",
  startDate: parseDt("Mar 2015"),
  endDate: parseDt("Mar 2016"),
  techologies: [JAVA, JAVASCRIPT, SCALA, MONGODB]
},
{
  companyName: "Bridge",
  location: "Melbourne, AU",
  startDate: parseDt("Mar 2016"),
  endDate: parseDt("Jan 2018"),
  techologies: [JAVASCRIPT, SCALA, MONGODB, GRAPHQL]
},
{
  companyName: "Shroogal",
  location: "Melbourne, AU",
  startDate: parseDt("Feb 2018"),
  endDate: parseDt("Sep 2018"),
  techologies: [JAVASCRIPT, DYNAMODB, GRAPHQL, AWS]
},
{
  companyName: "Homepass",
  location: "Melbourne, AU",
  startDate: parseDt("Sep 2018"),
  endDate: parseDt("Aug 2019"),
  techologies: [JAVASCRIPT, MONGODB, GRAPHQL, AWS]
},
{
  companyName: "OVO Energy Australia",
  location: "Melbourne, AU",
  startDate: parseDt("Aug 2019"),
  techologies: [SCALA, TYPESCRIPT, MONGODB, GRAPHQL, AWS, TERRAFORM]
},
]