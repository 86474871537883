// import styled from '@emotion/styled';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { JOHN_CAREER, renderDt } from './Career';

const CONTENT_STYLE = { color: '#151045' };
const ICON_STYLE = { background: '#151045' };

// https://github.com/stephane-monnot/react-vertical-timeline
// Would like the round dots to become elongated and to allow multiple overlapping
// elements, but hey...
export const Timeline = () => {
  return (
    <VerticalTimeline lineColor='#151045'>
      { JOHN_CAREER.map((careerJob) => 
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={CONTENT_STYLE}
          date={`${renderDt(careerJob.startDate)} - ${careerJob.endDate ? (renderDt(careerJob.endDate)): "Present"} `}
          iconStyle={ICON_STYLE}
        >
          <h3 className="vertical-timeline-element-title">{careerJob.companyName}</h3>
          <h4 className="vertical-timeline-element-subtitle">{careerJob.location}</h4>
          <p>
            {careerJob.techologies.map((t) => t.name).join(", ")}
          </p>
        </VerticalTimelineElement>
      )
    }
    </VerticalTimeline>
  )
}