import styled from "@emotion/styled";
import { ReactNode } from "react";
import { Content, H1, Headline, ItemBox, ItemBoxFooter } from "./ItemBox";

type HttpsLink = `https://${string}`

type Props = {
  name: string;
  link: HttpsLink;
  children: ReactNode | ReactNode[]
}


const H4 = styled('h4')`
  margin: 0;
  padding: 0;
`



export const ShowcaseItem = ({name, link, children}: Props) => {
  return (
    <ItemBox>
      <Headline>
        <H1><a href={link} target="_blank">{name}</a></H1>
      </Headline>
      <Content>
        {children}
      </Content>
      <ItemBoxFooter><H4><a target="_blank" href={link}>{link}</a></H4></ItemBoxFooter>
    </ItemBox>
  );
};